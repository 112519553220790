<template>
  <div class="table-responsive">
    <table
      class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
    >
      <thead>
        <tr class="fw-bolder text-muted">
          <th class="min-w-150px">Name & Tax ID</th>
          <th class="min-w-100px">Country</th>
          <th class="min-w-100px">City</th>
          <th class="min-w-140px">Phone</th>
          <th class="min-w-100px">Invoice Email</th>
          <th class="min-w-100px">Key Account Managers</th>
          <th class="min-w-100px">Invited By</th>
          <th class="min-w-100px">Default Branch</th>
          <th
            class="min-w-140px text-end"
            v-if="$can('company_edit', 'all') || $can('company_delete', 'all')"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="client in tableData" :key="client.id">
          <td>
            <div class="d-flex align-items-center">
              <div v-if="client.logo" class="symbol symbol-45px me-5">
                <Img :src="client.logo_url" alt="" />
              </div>
              <div class="d-flex justify-content-start flex-column">
                <router-link
                  :to="'/client/' + client.id"
                  class="text-dark fw-bolder text-hover-primary fs-6"
                  >{{ client.name }}</router-link
                >
                <span class="text-muted fw-bold text-muted d-block fs-7">
                  {{ client.tax_id }}
                </span>
              </div>
            </div>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block fs-6"
              >{{ client.country.title }}</a
            >
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block fs-6"
              >{{ client.city }}</a
            >
          </td>
          <td>
            <PhoneLink
              :phone="client.phone"
              css-classes="text-dark fw-bolder text-hover-primary d-block fs-6"
            ></PhoneLink>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block fs-6"
              >{{ client.invoice_email }}</a
            >
          </td>
          <td>
            <span
              class="text-muted fw-bold text-muted d-block fs-7"
              v-for="kam in client.key_account_managers"
              :key="kam.id"
            >
              <router-link :to="'/users/' + kam.id">
                {{ kam.name }}
              </router-link>
            </span>
          </td>
          <td>
            <router-link
              v-if="client.invited_by"
              :to="'/users/' + client.invited_by.id"
              class="text-dark fw-bolder text-hover-primary d-block fs-6"
              >{{ client.invited_by.name }}</router-link
            >
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block fs-6"
              >{{ client.default_branch ? client.default_branch.name : "" }}</a
            >
          </td>
          <td
            class="text-end"
            v-if="$can('company_edit', 'all') || $can('company_delete', 'all')"
          >
            <div class="btn-group btn-group-sm pb-2 pt-1" role="group">
              <router-link
                v-if="$can('company_edit', 'all')"
                :to="'/clients/edit/' + client.id"
                type="button"
                class="btn btn-sm btn-warning"
                >Edit</router-link
              >
              <a
                type="button"
                v-if="$can('company_delete', 'all')"
                class="btn btn-sm btn-danger"
                data-bs-toggle="modal"
                :data-bs-target="'#kt_modal_' + client.id"
                >Delete</a
              >
            </div>
          </td>
          <div class="modal fade" tabindex="-1" :id="'kt_modal_' + client.id">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Are you sure?</h5>

                  <!--begin::Close-->
                  <div
                    class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span class="svg-icon svg-icon-2x"></span>
                  </div>
                  <!--end::Close-->
                </div>

                <div class="modal-body">
                  <p>Do you want to delete {{ client.name }}?</p>
                </div>

                <div class="modal-footer">
                  <button class="btn btn-light" data-bs-dismiss="modal">
                    Close
                  </button>
                  <button
                    @click.prevent="destroyData(client.id)"
                    class="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import Img from "@/components/general/Img.vue";
import PhoneLink from "@/components/general/PhoneLink.vue";

export default defineComponent({
  name: "ClientsDataTable",
  components: {
    Img,
    PhoneLink
  },
  props: {
    tableData: Object
  },
  methods: {
    ...mapActions("ClientsModule", ["destroyData"])
  }
});
</script>

<style scoped>
.btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
