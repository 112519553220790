<template>
  <!--begin::clientsIndex-->
  <div class="row gy-5 g-xl-8">
    <ClientsIndex widget-classes="card-xxl-stretch mb-5 mb-xl-8"></ClientsIndex>
  </div>
  <!--end::clientsIndex-->
</template>

<script lang="js">
import { defineComponent, onMounted } from "vue";
import ClientsIndex from "@/components/clients/Index.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "ClientsView",
  components: {
    ClientsIndex
  },
  mounted() {
    setCurrentPageTitle("Clients");
  }
});
</script>
