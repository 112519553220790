<template>
  <div class="card" :class="widgetClasses">
    <div class="card mb-5 mb-xl-8">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">Clients</span>

          <span class="text-muted mt-1 fw-bold fs-7">List of Clients</span>
        </h3>
        <div class="d-flex align-items-center position-relative">
          <!--begin::Svg Icon | path: icons/duotone/General/Search.svg-->

          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />

                <path
                  d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                  opacity="0.3"
                />

                <path
                  d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                />
              </g>
            </svg>
          </span>

          <!--end::Svg Icon-->
          <div class="row">
            <div class="col">
              <SearchField
                @changeSearch="updateSearchFilter"
                :placeholder="'Search by Name'"
              ></SearchField>
            </div>
            <div class="col">
              <SearchField
                @changeSearch="updateTaxIdFilter"
                :placeholder="'Search by Tax ID'"
              ></SearchField>
            </div>
            <div class="col">
              <CountriesDropdown
                @change="updateSearchByCountry"
              ></CountriesDropdown>
            </div>
          </div>
        </div>
        <div
          class="card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="Click to add a new company"
        >
          <router-link to="/clients/new" class="btn btn-sm btn-light-primary"
            ><span class="svg-icon svg-icon-3"
              ><svg
                fill="none"
                viewBox="0 0 24 24"
                height="24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  xmlns="http://www.w3.org/2000/svg"
                  opacity="0.5"
                  x="11.364"
                  y="20.364"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-90 11.364 20.364)"
                  fill="black"
                ></rect>
                <rect
                  xmlns="http://www.w3.org/2000/svg"
                  x="4.36396"
                  y="11.364"
                  width="16"
                  height="2"
                  rx="1"
                  fill="black"
                ></rect></svg
            ></span>
            New Client
          </router-link>
        </div>
      </div>
      <div class="card-body py-3">
        <!--begin::Loading spinner-->
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <el-empty
          v-else-if="clientsList.length == 0"
          :description="
            'OOPS... Unfortunately no clients matching your filters were found.'
          "
        ></el-empty>
        <!--end::Loading spinner-->
        <ClientsDataTable v-else :tableData="clientsList"></ClientsDataTable>
      </div>
      <Pagination
        :currentPage="pagination.current_page"
        :totalPages="pagination.last_page"
        @changeCurrentPage="changeSearchByPage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import ClientsDataTable from "@/components/clients/ClientsDataTable.vue";
import SearchField from "@/components/general/SearchField.vue";
import CountriesDropdown from "@/components/dropdown/CountriesDropdown";
import Pagination from "@/components/pagination/Pagination.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue"

export default defineComponent({
  name: "ClientsIndex",
  components: {
    ClientsDataTable,
    SearchField,
    CountriesDropdown,
    Pagination,
    LoadingSpinner
  },
  props: {
    widgetClasses: String
  },
  data() {
    return {
      pagination: {},
      query: {
        search: "",
        taxId: "",
        countryId: ""
      }
    };
  },
  methods: {
    ...mapActions("ClientsModule", [
      "fetchData",
      "setQuery",
      "setPageNumber",
      "resetPageNumber",
      "resetState"
    ]),
    updateSearchFilter(textInput) {
      this.query.search = textInput;
      this.setQuery(this.query);
      this.fetchData();
    },
    updateTaxIdFilter(textInput) {
      this.query.taxId = textInput;
      this.setQuery(this.query);
      this.fetchData();
    },
    updateSearchByCountry(textInput) {
      this.query.countryId = textInput;
      this.setQuery(this.query);
      this.fetchData();
    },
    changeSearchByPage(pageNumber) {
      this.setPageNumber(pageNumber);
      this.fetchData();
    }
  },
  computed: {
    ...mapGetters("ClientsModule", [
      "clientsList",
      "loading",
      "getPaginationData"
    ])
  },
  created() {
    this.fetchData();
  },
  updated() {
    this.pagination = this.getPaginationData;
  },
  beforeUnmount() {
    this.resetState()
  }
});
</script>
