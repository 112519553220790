<template>
  <el-select
    v-model="value"
    filterable
    size="large"
    clearable
    placeholder="Country"
    class="w-100"
  >
    <el-option
      v-for="item in countries"
      :key="item.id"
      :label="item.title"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "CountriesDropdown",
  props: {
    defaultValue: [Number, String]
  },
  data() {
    return {
      value: ""
    };
  },
  watch: {
    defaultValue: {
      deep: true,
      handler() {
        this.value = this.defaultValue;
      }
    }
  },
  methods: {
    ...mapActions("ListsModule", ["fetchCountries"])
  },
  mounted() {
    this.fetchCountries().then(() => {
      if (this.$route.query.country_id) {
        this.value = this.countries.find(
          i => i.id == this.$route.query.country_id
        ).title;
      }
    });
    if (this.defaultValue) this.value = this.defaultValue;
  },
  computed: {
    ...mapGetters("ListsModule", ["countries"])
  }
});
</script>
