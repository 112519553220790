<template>
  <div>
    <span v-if="phone">
      <a
        class="ps-0"
        :class="cssClasses"
        :href="'tel:' + phone"
        :title="'Dial ' + phone"
      >
        <i class="fa fa-fw fa-phone me-1"></i>
        {{ phone }}
      </a>
    </span>
  </div>
</template>

<script>

export default {
  props: ["phone", "cssClasses"]
};
</script>
